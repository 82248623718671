import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";

import AssetComponent from "../components/Asset";

import {
  IonContent,
  IonLoading,
  IonHeader,
  IonToolbar,
  IonPage,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

const INITIAL_QUERY = gql`
  query getAsset($id: ID!) {
    asset(id: $id) {
      id
      name
      thumbnail
      notes
      mediaType
      created
      project {
        name
      }
      studio {
        name
      }
    }
    previews(asset: $id) {
      id
      language
      label
      revision
      type
    }
  }
`;

const PREVIEW_QUERY = gql`
  query getAsset($id: ID!, $previewId: ID) {
    asset(id: $id) {
      id
      name
      thumbnail
      notes
      mediaType
      created
      project {
        name
      }
      studio {
        name
      }
    }
    previews(asset: $id) {
      id
      language
      label
      revision
      type
    }
    preview(id: $previewId) {
      path
    }
  }
`;

function Assets(props) {
  const { id } = useParams();

  const [preview, setPreview] = useState(null);
  const [revision, setRevision] = useState(null);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [variables, setVariables] = useState({ id });

  const { loading, error, data } = useQuery(query, { variables });

  useEffect(() => {
    if (data) {
      var previewId;
      if (!preview) {
        let ovPreviews = data.previews.filter(
          (preview) => preview.language === "ENG"
        );
        previewId = (ovPreviews[0] || data.previews[0]).id;
      } else {
        previewId = preview;
      }
      setPreview(previewId);
      setQuery(PREVIEW_QUERY);
      setVariables({ id, previewId: previewId });
    }
  }, [data, id, preview]);

  if (error) return "Error";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonLoading isOpen={loading} message={"Please wait..."} />
        ) : (
          <AssetComponent
            data={data}
            revision={revision}
            preview={preview}
            setRevision={setRevision}
            setPreview={setPreview}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

export default Assets;
