import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";

import { pushHistory } from "../services/utils";

import {
  IonContent,
  IonSearchbar,
  IonLoading,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  IonPage,
  IonButtons,
  IonBackButton,
  IonHeader,
} from "@ionic/react";

const QUERY = gql`
  query getAssets($project: ID) {
    assets(project: $project) {
      id
      name
      thumbnail
      created
      mediaType
      project {
        name
      }
    }
  }
`;

function Project(props) {
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const [mediaType, setMediaType] = useState(query.get("mediaType") || "video");
  const [searchText, setSearchText] = useState(query.get("searchText") || "");
  const [filteredAssets, setFilteredAssets] = useState([]);

  const { loading, error, data } = useQuery(QUERY, {
    variables: { project: id },
  });

  useEffect(() => {
    if (!data) return;
    let regex = new RegExp(searchText, "i");
    setFilteredAssets(
      data.assets.filter(
        (asset) =>
          asset.mediaType === mediaType &&
          (!searchText || asset.name.match(regex))
      )
    );
  }, [data, mediaType, searchText]);

  useEffect(() => {
    pushHistory({ searchText, mediaType });
  }, [searchText, mediaType]);

  if (error) return "Error";

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value)}
        ></IonSearchbar>
        <IonToolbar>
          <IonSegment
            value={mediaType}
            onIonChange={(e) => setMediaType(e.detail.value)}
          >
            <IonSegmentButton value="video">
              <IonLabel>Video</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="image">
              <IonLabel>Image</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="document">
              <IonLabel></IonLabel>
              Document
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        {loading ? (
          <IonLoading isOpen={loading} message={"Please wait..."} />
        ) : (
          filteredAssets.map((asset) => (
            <IonCard key={asset.name}>
              <IonCardHeader>
                <IonCardSubtitle>{asset.project.name}</IonCardSubtitle>
                <IonCardTitle>{asset.name}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <Link to={`/tabs/asset/${asset.id}`}>
                  <img
                    src={`https://resizer.pixwel.com?url=https:${asset.thumbnail}&width=300`}
                    alt={asset.name}
                  />
                </Link>
                <small>
                  Added {moment.unix(asset.created).format("MMMM Do YYYY")}
                </small>
              </IonCardContent>
            </IonCard>
          ))
        )}
      </IonContent>
    </IonPage>
  );
}

export default Project;
