import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import {
  IonContent,
  IonLoading,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

const QUERY = gql`
  query getShare($id: ID!) {
    share(id: $id) {
      id
      from {
        name
        emailNormal
      }
      to {
        name
        emailNormal
      }
      groups {
        name
      }
      assets {
        id
        name
        thumbnail
        created
        project {
          name
        }
      }
      projectNames
      notes
      feedback
      expired
    }
  }
`;

function Share(props) {
  const { id } = useParams();

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
  });

  console.log({ data });

  if (error) return "Error";

  return (
    <IonPage>
      <IonHeader>
        <IonTitle>Share</IonTitle>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonLoading isOpen={loading} message={"Please wait..."} />
        ) : (
          data.share.assets.map((asset) => (
            <IonCard key={asset.name}>
              <IonCardHeader>
                <IonCardSubtitle>{asset.project.name}</IonCardSubtitle>
                <IonCardTitle>{asset.name}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <Link to={`/tabs/asset/${asset.id}`}>
                  <img
                    src={`https://resizer.pixwel.com?url=https:${asset.thumbnail}&width=300`}
                    alt={asset.name}
                  />
                </Link>
                <small>
                  Added {moment.unix(asset.created).format("MMMM Do YYYY")}
                </small>
              </IonCardContent>
            </IonCard>
          ))
        )}
      </IonContent>
    </IonPage>
  );
}

export default Share;
