import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";

function Login(props) {
  let [username, setUsername] = useState(null);
  let [password, setPassword] = useState(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              name="email"
              onIonChange={(e) => setUsername(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput
              name="password"
              type="password"
              onIonChange={(e) => setPassword(e.detail.value)}
            ></IonInput>
          </IonItem>
        </IonList>

        <IonButton
          expand="full"
          color="primary"
          onClick={() => props.handleLogin({ username, password })}
        >
          Login
        </IonButton>
      </IonContent>
    </IonPage>
  );
}

export default Login;
