import React from "react";
import { useContext } from "react";
import { withRouter } from "react-router-dom";

import { SessionContext } from "../context";

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import {
  notificationsOutline,
  caretForwardOutline,
  shareOutline,
  cartOutline,
} from "ionicons/icons";

import "./Menu.css";

const appPages = [
  {
    title: "Projects",
    url: "/projects",
    iosIcon: caretForwardOutline,
    mdIcon: caretForwardOutline,
  },
  {
    title: "Shares",
    url: "/shares",
    iosIcon: shareOutline,
    mdIcon: shareOutline,
  },
  {
    title: "Orders",
    url: "/orders",
    iosIcon: cartOutline,
    mdIcon: cartOutline,
  },
  {
    title: "Notifications",
    url: "/notifications",
    iosIcon: notificationsOutline,
    mdIcon: notificationsOutline,
  },
];

const Menu = ({ selectedPage }) => {
  let user = useContext(SessionContext);
  let seed = user.name ? user.name : user.emailNormal;
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <img
            alt={user.emailNormal}
            style={{ width: "30px" }}
            src={`https://avatars.dicebear.com/v2/initials/${seed}.svg`}
          />
          <IonListHeader>Pixwel</IonListHeader>
          <IonNote>{user.emailNormal}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={selectedPage === appPage.title ? "selected" : ""}
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
