/**
 * Pushes history state without notifying the router to navigate. This is used
 * to make the state bookmarkable.
 *
 * @param {object} obj
 */
function pushHistory(obj) {
  let qs = querystring(obj);
  window.history.pushState(null, "", `${window.location.pathname}?${qs}`);
}

/**
 * Converts a querystring object into a string
 *
 * @param {object} params
 * @returns {string}
 */
function querystring(params) {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
}

export { pushHistory, querystring };
