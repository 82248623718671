import btoa from "btoa";
import fetch from "node-fetch";
import config from "../config";

function login(data) {
  let user = localStorage.getItem("user");
  let token = localStorage.getItem("token");

  if (token) {
    return { user: JSON.parse(user), token };
  }

  if (!token && !data) {
    return false;
  }

  var hash = btoa(`${data.username}:${data.password}`);

  return fetch(config.api + "/api/session", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Basic " + hash,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      let token = btoa(`${data.username}:token-${res.token}`);
      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("token", token);
      return { user: res, token };
    });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
}

export { login, logout };
