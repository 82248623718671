import React from "react";
import { Route, Redirect } from "react-router-dom";

import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";

import {
  notificationsOutline,
  caretForwardOutline,
  shareOutline,
  cartOutline,
} from "ionicons/icons";

import "./Page.css";

import Notifications from "../pages/Notifications";
import Projects from "../pages/Projects";
import Project from "../pages/Project";
import Asset from "../pages/Asset";
import Shares from "../pages/Shares";
import Share from "../pages/Share";

const Page = ({ match }) => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route path="/tabs/catalog" render={() => <Projects />} />
        <Route
          path="/tabs/notifications"
          render={(props) => <Notifications {...props} />}
          exact={true}
        />
        <Route
          path="/tabs/project/:id"
          render={() => <Project />}
          exact={true}
        />
        <Route path="/tabs/asset/:id" render={() => <Asset />} />
        <Route path="/tabs/shares" render={(props) => <Shares {...props} />} />
        <Route path="/tabs/share/:id?" render={() => <Share />} />
        <Redirect to="/tabs/catalog" exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="catalog" href="/tabs/catalog">
          <IonIcon icon={caretForwardOutline} />
          <IonLabel>Catalog</IonLabel>
        </IonTabButton>
        <IonTabButton tab="shares" href="/tabs/shares">
          <IonIcon icon={shareOutline} />
          <IonLabel>Shares</IonLabel>
        </IonTabButton>
        <IonTabButton tab="orders" href="/tabs/workrequests">
          <IonIcon icon={cartOutline} />
          <IonLabel>Orders</IonLabel>
        </IonTabButton>
        <IonTabButton tab="alerts" href="/tabs/notifications">
          <IonIcon icon={notificationsOutline} />
          <IonLabel>Alerts</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Page;
