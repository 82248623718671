import Menu from "./components/Menu";
import Page from "./pages/Page";
import React, { useState, useEffect } from "react";
import { IonApp, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { ApolloClient, from, HttpLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "@apollo/link-error";

import Login from "./pages/Login";
import { SessionContext } from "./context";
import { login, logout } from "./services/auth";

import config from "./config";

const App = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [client, setClient] = useState(null);

  useEffect(() => {
    let { token, user } = login();
    setToken(token);
    setUser(user);
  }, []);

  useEffect(() => {
    let errorLink = new onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              logout();
              setUser(false);
              break;
            default:
              return forward(operation);
          }
        }
      }
    });

    var instance = new ApolloClient({
      link: from([
        errorLink,
        new HttpLink({
          uri: config.graphql,
          headers: { Authorization: `Basic ${token}` },
        }),
      ]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-first",
          errorPolicy: "all",
        },
      },
    });
    setClient(instance);
  }, [token]);

  const handleLogin = async (data) => {
    // @todo remove reload, but this was the only way out of an infinite loop
    await login(data);
    window.location.reload();
  };

  return (
    <div>
      {!user ? (
        <Login handleLogin={handleLogin} />
      ) : (
        <SessionContext.Provider value={user}>
          <ApolloProvider client={client}>
            <IonApp>
              <IonReactRouter>
                <IonSplitPane contentId="main">
                  <Menu />
                  <Page id="main" />
                </IonSplitPane>
              </IonReactRouter>
            </IonApp>
          </ApolloProvider>
        </SessionContext.Provider>
      )}
    </div>
  );
};

export default App;
