import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import {
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCardTitle,
} from "@ionic/react";

function Asset(props) {
  const handleImageClick = () => {
    // @todo change to modal
    window.location.href = `https://resizer.pixwel.com?url=https:${props.data.asset.thumbnail}&width=500`;
  };

  return (
    <IonCard>
      {props.data &&
      props.data.asset.mediaType !== "video" &&
      props.data.asset ? (
        <img
          src={`https://resizer.pixwel.com?url=https:${props.data.asset.thumbnail}&width=350`}
          alt={props.data.asset.name}
          width="100%"
          onClick={handleImageClick}
        />
      ) : null}
      {props.data &&
      props.data.asset.mediaType === "video" &&
      props.data.preview ? (
        <video
          src={props.data.preview.path}
          poster={`https://resizer.pixwel.com?url=https:${props.data.asset.thumbnail}&width=350`}
          width="100%"
          controls
        ></video>
      ) : null}
      <IonCardHeader>
        <IonCardSubtitle>{props.data.asset.studio.name}</IonCardSubtitle>
        <IonCardSubtitle>{props.data.asset.project.name}</IonCardSubtitle>
        <IonCardTitle>{props.data.asset.name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {props.data.asset.notes}
        <IonItem>
          <IonLabel>Language</IonLabel>
          <IonSelect
            value={props.preview}
            placeholder="Select One"
            onIonChange={(e) => props.setPreview(e.detail.value)}
          >
            {props.data
              ? props.data.previews.map((preview) => (
                  <IonSelectOption key={preview.id} value={preview.id}>
                    {preview.language}
                  </IonSelectOption>
                ))
              : null}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Revision</IonLabel>
          <IonSelect
            value={props.revision}
            placeholder="Select One"
            onIonChange={(e) => props.setRevision(e.detail.value)}
          >
            <IonSelectOption value="female">Final</IonSelectOption>
            <IonSelectOption value="male">REV 1</IonSelectOption>
          </IonSelect>
        </IonItem>
        <br />
        <br />
        <IonButton expand="block" fill="outline">
          Share
        </IonButton>
        <br />
        <small>
          Added {moment.unix(props.data.asset.created).format("MMMM Do YYYY")}
        </small>
      </IonCardContent>
    </IonCard>
  );
}

Asset.propTypes = {
  data: PropTypes.object,
  revision: PropTypes.string,
  preview: PropTypes.string,
  setPreview: PropTypes.func,
  setRevision: PropTypes.func,
};

export default Asset;
