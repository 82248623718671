import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonContent,
  IonTitle,
  IonPage,
  IonHeader,
  IonToolbar,
} from "@ionic/react";

const QUERY = gql`
  {
    notifications(limit: 50) {
      id
      title
      read
    }
  }
`;

function Notifications() {
  const { loading, error, data } = useQuery(QUERY);

  let notifications = [];

  if (data) {
    notifications = data.notifications.map((n) => {
      let [user, asset] = n.title.split("has submitted work request");
      return { id: n.id, user, asset };
    });
  }

  if (error) return "Error";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Alerts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {loading && (
            <IonLoading isOpen={loading} message={"Please wait..."} />
          )}
          {!loading && (
            <React.Fragment>
              {notifications.map((notification) => (
                <IonItemSliding key={notification.id}>
                  <IonItem>
                    <div slot="start" className="unread"></div>
                    <IonLabel>
                      <small>
                        <em>{notification.user}</em>
                      </small>
                      <br />
                      <small>{notification.asset}</small>
                    </IonLabel>
                  </IonItem>
                  <IonItemOptions side="end">
                    <IonItemOption onClick={() => {}}>Unread</IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
            </React.Fragment>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default Notifications;
