import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  IonContent,
  IonLoading,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonIcon,
  IonCardContent,
  IonChip,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonPage,
  IonTitle,
} from "@ionic/react";

import { chatboxOutline, checkmarkCircleOutline } from "ionicons/icons";

const FROM_QUERY = gql`
  query getShares($from: Boolean, $offset: Int) {
    shares(
      from: $from
      offset: $offset
      orderBy: "id"
      orderDirection: "desc"
    ) {
      id
      from {
        name
        emailNormal
      }
      to {
        name
        emailNormal
      }
      assets {
        name
        thumbnail
      }
      feedback
      projectNames
      notes
      expired
      created
    }
  }
`;

const TO_QUERY = gql`
  query getShares($to: Boolean, $offset: Int) {
    shares(to: $to, offset: $offset) {
      id
      from {
        name
        emailNormal
      }
      to {
        name
        emailNormal
      }
      assets {
        name
        thumbnail
      }
      feedback
      projectNames
      notes
      expired
      created
    }
  }
`;

function Shares() {
  const [direction, setDirection] = useState("from");

  const QUERY = direction === "from" ? TO_QUERY : FROM_QUERY;
  const variables =
    direction === "from" ? { to: true, offset: 0 } : { from: true, offset: 0 };

  const { loading, error, data, fetchMore } = useQuery(QUERY, { variables });

  const loadMore = () => {
    fetchMore({
      variables: { offset: data.shares.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          shares: [...prev.shares, ...fetchMoreResult.shares],
        });
      },
    });
  };

  if (error) return "Error";

  return (
    <IonPage id="shares">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Shares</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonSegment
              value={direction}
              onIonChange={(e) => setDirection(e.detail.value)}
            >
              <IonSegmentButton value="from">
                <IonLabel>Received</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="to">
                <IonLabel>Sent</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
        {loading ? (
          <IonLoading isOpen={loading} message={"Please wait..."} />
        ) : (
          data.shares.map((share) => (
            <Link
              key={share.id}
              to={`/tabs/share/${share.id}`}
              style={{ textDecoration: "none" }}
            >
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>
                    <span style={{ float: "right" }}>
                      {moment
                        .unix(share.created)
                        .fromNow("MMM Do")
                        .toUpperCase()}
                    </span>
                    {direction === "from" ? (
                      <span>{share.from.name || share.from.emailNormal}</span>
                    ) : (
                      <span>
                        {share.to
                          .map((to) => {
                            return to.name || to.emailNormal;
                          })
                          .join(", ")}
                      </span>
                    )}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  {share.notes ? (
                    <div>
                      <small>{share.notes}</small>
                      <br />
                      <br />
                    </div>
                  ) : null}
                  <IonCardSubtitle>
                    {share.projectNames.map((project) => (
                      <IonChip key={project} color="primary" outline="true">
                        <IonLabel color="primary">{project}</IonLabel>
                      </IonChip>
                    ))}
                  </IonCardSubtitle>
                  {/* <IonCardSubtitle>
                    {share.assets.map((asset) => (
                    <IonChip>
                      <IonLabel color="secondary">{asset.name}</IonLabel>
                    </IonChip>
                  ))}
                  </IonCardSubtitle> */}
                  {!share.feedback.enabled ? (
                    <div>
                      <small style={{ float: "right" }}>
                        {share.assets.length} assets
                      </small>
                      {Math.random() >= 0.5 ? (
                        <React.Fragment>
                          <IonIcon icon={chatboxOutline} />
                          &nbsp;
                          <small>Feedback Required</small>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <IonIcon icon={checkmarkCircleOutline} />
                          &nbsp;
                          <small>Voted!</small>&nbsp;
                        </React.Fragment>
                      )}
                    </div>
                  ) : null}
                </IonCardContent>
              </IonCard>
            </Link>
          ))
        )}
        {!loading && data.shares.length ? (
          <IonButton expand="block" fill="outline" onClick={loadMore}>
            Load More
          </IonButton>
        ) : null}
        <br />
        <br />
        <br />
      </IonContent>
    </IonPage>
  );
}

export default Shares;
