import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { pushHistory } from "../services/utils";

import {
  IonContent,
  IonSearchbar,
  IonLoading,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  IonPage,
  IonHeader,
} from "@ionic/react";

const QUERY = gql`
  {
    projects(limit: 300) {
      id
      name
      poster
      archived
      studio {
        name
      }
    }
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem;
`;

function Projects() {
  const query = new URLSearchParams(useLocation().search);
  const { loading, error, data } = useQuery(QUERY);
  const [archived, setArchived] = useState(
    query.get("archived") === "true" || false
  );
  const [searchText, setSearchText] = useState(query.get("searchText") || "");
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (!data) return;
    let regex = new RegExp(searchText, "i");
    setFilteredProjects(
      data.projects.filter(
        (project) =>
          project.archived === archived &&
          (!searchText || project.name.match(regex))
      )
    );
  }, [archived, data, searchText]);

  useEffect(() => {
    pushHistory({ searchText, archived });
  }, [archived, searchText]);

  if (error) return "Error";

  return (
    <IonPage id="projects">
      <IonHeader>
        <IonToolbar style={{ marginTop: "10px" }}>
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => setSearchText(e.detail.value)}
          ></IonSearchbar>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={archived}
            onIonChange={(e) => setArchived(e.detail.value === "true")}
          >
            <IonSegmentButton value={false}>
              <IonLabel>Active</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={true}>
              <IonLabel>Archived</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonLoading isOpen={loading} message={"Please wait..."} />
        ) : (
          <React.Fragment>
            <ProjectsGrid>
              {filteredProjects.map((project) => (
                <IonCard key={project.name}>
                  <IonCardHeader>
                    <IonCardSubtitle>{project.studio.name}</IonCardSubtitle>
                    <IonCardTitle>{project.name}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <Link to={`/tabs/project/${project.id}`}>
                      <img
                        src={`https://resizer.pixwel.com?url=https:${project.poster}&width=350`}
                        alt={project.name}
                      />
                    </Link>
                  </IonCardContent>
                </IonCard>
              ))}
            </ProjectsGrid>
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
}

export default Projects;
